import { createSlice } from '@reduxjs/toolkit'

interface GlobalState {
  pageNotFound: boolean
  countries: any[]
  profileID: null | number
  selectedItems: any[]
}

const initialState: GlobalState = {
  pageNotFound: false,
  countries: [],
  profileID: null,
  selectedItems: [],
}

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setValue(state, action) {
      return Object.assign({}, state, action.payload)
    },
    setPageNotFound(state, action) {
      state.pageNotFound = action.payload
    },
    selectItem(state, action) {
      const { id } = action.payload
      if (!state.selectedItems.includes(id)) {
        state.selectedItems.push(id)
      }
    },
    diselectItem(state, action) {
      const { id } = action.payload
      const index = state.selectedItems.indexOf(id)
      if (state.selectedItems.includes(id)) {
        state.selectedItems.splice(index, 1)
      }
    },
  },
})

export const { setValue, setPageNotFound, selectItem, diselectItem } = globalSlice.actions
export default globalSlice.reducer
