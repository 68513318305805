export function getSearchParams() {
  const params = new URLSearchParams(window.location.search)
  return params
}

export function updatedSearchParams(filters) {
  const params = getSearchParams()

  for (let key in filters) {
    params.delete(key)
    const value = filters[key]
    if (value) {
      params.append(key, value)
    }
  }
  return params
}

export function handleSubmit(filters, updatedParams = {}, refetch, navigate) {
  // 1. Update filters
  Object.assign(filters, updatedParams)
  if (updatedParams?.page === undefined) {
    filters.page = null
  }
  // 2. Send request
  const promise = refetch()
  // 3. Push params to path
  const newParams = updatedSearchParams(filters)
  promise
    .then(
      navigate({
        pathname: window.location.pathname,
        search: newParams.toString(),
      }),
    )
    .catch()
}
