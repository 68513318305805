import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: '',
  type: null,
  number: '',
  bank_account: '',
  bank_account_label: '',
  currency: null,
  date_of_issue: new Date().toISOString(),
  sale_date: new Date().toISOString(),
  payment_deadline: new Date().toISOString(),
  language: null,
  buyer: null,
  buyer_name: '',
  buyer_intername: '',
  buyer_reg_number: '',
  buyer_address: {
    country: '',
    postcode: '',
    city: '',
    street: '',
    building: '',
    office: '',
  },
  consignee: null,
  consignee_name: '',
  consignee_intername: '',
  consignee_reg_number: '',
  consignee_address: {
    country: '',
    postcode: '',
    city: '',
    street: '',
    building: '',
    office: '',
  },
  comments: '',
  documentitem_set: [],
  errors: {},
}

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setValue(state, action) {
      Object.keys(action.payload).forEach((key) => {
        if (key in state.errors) {
          delete state.errors[key as keyof typeof state.errors]
        }
      })
      Object.assign(state, action.payload)
    },
    reset(state, action) {
      return { ...initialState }
    },
    moveLotUp(state, action) {
      let lots = JSON.parse(JSON.stringify(state.documentitem_set))
      const topLot = lots[action.payload - 1]
      const currentLot = lots[action.payload]
      lots[action.payload] = topLot
      lots[action.payload - 1] = currentLot
      state.documentitem_set = lots
    },
    moveLotDown(state, action) {
      let lots = JSON.parse(JSON.stringify(state.documentitem_set))
      const bottonLot = lots[action.payload + 1]
      const currentLot = lots[action.payload]
      lots[action.payload] = bottonLot
      lots[action.payload + 1] = currentLot
      state.documentitem_set = lots
    },
    addLot(state) {
      let lots = JSON.parse(JSON.stringify(state.documentitem_set))
      lots.push({})
      state.documentitem_set = lots
    },
    removeLot(state, action) {
      let lots = JSON.parse(JSON.stringify(state.documentitem_set))
      const newLots = lots.filter((_item: number, index: number) => index !== action.payload)
      state.documentitem_set = newLots
    },
    updateLot(state, action) {
      let lots = JSON.parse(JSON.stringify(state.documentitem_set))
      lots[action.payload.index] = action.payload.value
      state.documentitem_set = lots
    },
  },
})

export const { setValue, reset, moveLotUp, moveLotDown, addLot, removeLot, updateLot } =
  documentSlice.actions
export default documentSlice.reducer
