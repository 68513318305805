import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  open: localStorage.getItem('sidebarOpen') === 'true',
  showMenu: false,
}

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebar: (state, action) => {
      if (action.payload.open !== undefined) {
        localStorage.setItem('sidebarOpen', action.payload.open)
      }
      return Object.assign({}, state, action.payload)
    },
  },
})

export const { setSidebar } = sidebarSlice.actions
export default sidebarSlice.reducer
