import './Cookies.scss'
import React from 'react'
import PropTypes from 'prop-types'

const Cookies = ({ handleAccept, handleReject }) => {
  return (
    <div className="cookie-consent">
      <div className="cookie-consent">
        <p>
          We use 🍪 cookies to improve your experience on our site. You may customize your cookie
          preferences <u>here</u>
        </p>
        <button className="btn btn-sm btn-primary" onClick={handleAccept}>
          Accept
        </button>{' '}
        <button className="btn btn-sm btn-secondary" onClick={handleReject}>
          Reject
        </button>
      </div>
    </div>
  )
}

Cookies.propTypes = {
  handleAccept: PropTypes.func,
  handleReject: PropTypes.func,
}

export default Cookies
