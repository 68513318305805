export const API_LANDING = '/api/landing/'
export const API_DASHBOARD = '/api/dashboard/'
export const API_PRODUCTS = '/api/products/'
export const API_MARKET_PRODUCTS = '/api/publicproducts/'
export const API_TARIFF_NUMBERS = '/api/tarics/'
export const API_COUNTRIES = '/api/countries/'
export const API_LANGUAGES = '/api/languages/'
export const API_PHONE_CODES = '/api/phone-codes/'
export const API_REG_TYPES = '/api/reg-types/'
export const API_CURRENCIES = '/api/currencies/'
export const API_CATEGORIES = '/api/product-categories/'
export const API_SYSTEMS = '/api/systems/'
export const API_UNITS = '/api/units/'
export const API_INQUIRES = '/api/inquires/'
export const API_DOCUMENTS = '/api/documents/'
export const API_DOC_TYPES = '/api/document-types/'
export const API_QUOTATIONS = '/api/quotations/'
export const API_COMPANIES = '/api/companies/'
export const API_OFFERS = '/api/offers/'
export const API_PROFILE = '/api/profile/'
export const API_ORGANIZATIONS = '/api/organizations/'
export const API_BANKS = '/api/banks/'
export const API_BANK_ACCOUNTS = '/api/bank-accounts/'
export const API_USERS = '/api/users/'
export const API_ORGANIZATION_USERS = '/api/organization-users/'
export const API_ROLES = '/api/roles/'
export const API_GROUPS = '/api/groups/'
export const API_PERMISSIONS = '/api/permissions/'
export const API_CONFIRM_EMAIL = '/confirm-email/'
export const API_PERSON_ORGANIZATIONS = '/api/person-organizations/'
export const API_ORDERS = '/api/orders/'
export const API_ORDER_ITEMS = '/api/orderitems/'
export const API_UNAUTH_ORDERS = '/api/unauth-orders/'
export const API_UNAUTH_ORDER_ITEMS = '/api/unauth-orderitems/'
export const API_BUYER_REQUEST = '/api/buyer-request/'
export const API_UNAUTH_BUYER_REQUEST = '/api/unauth-buyer-request/'
