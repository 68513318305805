import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  asideShow: false,
}

const asideSlice = createSlice({
  name: 'aside',
  initialState,
  reducers: {
    setAsideOpen: (state, action) => {
      state.asideShow = action.payload
    },
    setAsideClose: (state, action) => {
      state.asideShow = action.payload
    },
  },
})

export const { setAsideOpen, setAsideClose } = asideSlice.actions
export default asideSlice.reducer
