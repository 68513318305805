import React from 'react'

// Dashboard
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
// Landing
const Landing = React.lazy(() => import('./views/pages/landing/Landing'))
// Checkout
const Checkout = React.lazy(() => import('./views/pages/checkout/Checkout'))
// Products request
const QuotationRequest = React.lazy(() => import('./views/pages/checkout/QuotationRequest'))
// Public product
const PublicProducts = React.lazy(() => import('./views/pages/landing/PublicProducts'))
// Public company
const PublicCompanies = React.lazy(() => import('./views/pages/landing/PublicCompanies'))
// My Products
const Products = React.lazy(() => import('./views/products/Products'))
const ProductForm = React.lazy(() => import('./views/products/Form'))
// My Stock
const Stock = React.lazy(() => import('./views/stock/Stock'))
// My Inquires
const Inquires = React.lazy(() => import('./views/inquires/Inquires'))
const InquireForm = React.lazy(() => import('./views/inquires/Form'))
// Quotations
const Quotations = React.lazy(() => import('./views/quotations/Quotations'))
const QuotationForm = React.lazy(() => import('./views/quotations/Form'))
// Companies
const Companies = React.lazy(() => import('./views/companies/Companies'))
// Profile
const Profile = React.lazy(() => import('./views/profile/Form'))
// My Organizations
const Organizations = React.lazy(() => import('./views/organizations/Organizations'))
const OrganizationForm = React.lazy(() => import('./views/organizations/Form'))
const OrganizationSettings = React.lazy(() => import('./views/organizations/Settings'))

// Documents
const Documents = React.lazy(() => import('./views/documents/Documents'))
const DocumentForm = React.lazy(() => import('./views/documents/Form'))

const routes = [
  { path: '/', exact: true, name: 'Home', component: Landing },
  { path: '/checkout', exact: true, name: 'Checkout', component: Checkout },
  {
    path: '/quotationrequest',
    exact: true,
    name: 'Quotation Request',
    component: QuotationRequest,
  },
  { path: '/publicproducts/:id', exact: true, name: 'Public Products', component: PublicProducts },
  {
    path: '/publiccompanies/:id',
    exact: true,
    name: 'Public Companies',
    component: PublicCompanies,
  },
  { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/profile', exact: true, name: 'Profile', component: Profile },
  { path: '/products', exact: true, name: 'Products', component: Products },
  {
    path: '/products/new',
    exact: true,
    name: 'Create Product',
    component: ProductForm,
  },
  {
    path: '/products/:id',
    name: 'Product Details',
    component: ProductForm,
  },
  { path: '/products/:id/copy', name: 'Product Details', component: ProductForm },
  { path: '/stock', exact: true, name: 'Stock', component: Stock },
  { path: '/inquires', exact: true, name: 'Inquires', component: Inquires },
  { path: '/inquires/new', exact: true, name: 'Create Inquire', component: InquireForm },
  { path: '/inquires/:id/copy', name: 'Inquire Details', component: InquireForm },
  { path: '/inquires/:id', name: 'Inquire Details', component: InquireForm },
  { path: '/tenders', exact: true, name: 'Tenders', component: Quotations },
  { path: '/tenders/:id', name: 'Tender Details', component: QuotationForm },
  { path: '/companies', exact: true, name: 'Companies', component: Companies },
  // { path: '/companies/:id', exact: true, name: 'Company details', component: Companies },
  { path: '/organizations', exact: true, name: 'Organizations', component: Organizations },
  {
    path: '/organizations/settings/:id',
    name: 'Organization Details',
    component: OrganizationSettings,
  },
  { path: '/organizations/:id', name: 'Organization Details', component: OrganizationForm },
  {
    path: '/organizations/new',
    exact: true,
    name: 'Create/Join Organization',
    component: OrganizationForm,
  },
  {
    path: '/documents',
    exact: true,
    name: 'Documents',
    component: Documents,
  },
  { path: '/documents/new', exact: true, name: 'Create Document', component: DocumentForm },
  { path: '/documents/:id/copy', name: 'Document copy', component: DocumentForm },
  { path: '/documents/:id', name: 'Document Details', component: DocumentForm },
]

export default routes
