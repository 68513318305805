import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import axios from 'axios'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { message } from 'antd'
import { API } from './consts'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store'

// Translations
import globalEN from './translations/en/global.json'
import globalUK from './translations/uk/global.json'
import globalPL from './translations/pl/global.json'
import globalRU from './translations/ru/global.json'
import i18next from 'i18next'
import { I18nextProvider } from 'react-i18next'

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  fallbackLng: 'en',
  resources: {
    en: {
      translation: globalEN,
    },
    uk: {
      translation: globalUK,
    },
    pl: {
      translation: globalPL,
    },
    ru: {
      translation: globalRU,
    },
  },
})

const language = localStorage.getItem('language')
if (language) {
  i18next.changeLanguage(language.toLowerCase())
}

// Set base url as default
axios.defaults.baseURL = `${API.root}/`

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    const authToken = localStorage.getItem('authToken')
    config.headers['Authorization'] = `Token ${authToken}`
    const sessionId = localStorage.getItem('sessionid')
    config.headers['X-CSRFTOKEN'] = sessionId //  Django Rest Framework
    config.headers['Accept-Language'] = language //  Django Rest Framework

    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (401 === error?.response?.status) {
      localStorage.removeItem('authToken')
      // delete axios.defaults.headers.common["Authorization"];
    } else {
      return Promise.reject(error)
    }
  },
)

// Set alert config
message.config({
  top: 45,
  duration: 5,
  maxCount: 1,
  // rtl: true,
  prefixCls: 'my-message',
})

const queryClient = new QueryClient()

const root = document.getElementById('root')
const reactRoot = createRoot(root)

reactRoot.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </QueryClientProvider>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
