import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  name: '',
  intername: '',
  name_full: '',
  image: null,
  imageSource: null,
  reg_number: '',
  duns: '',
  website: '',
  email: '',
  org_phone: '',
  fax: '',
  country: '',
  postcode: '',
  city: '',
  street: '',
  building: '',
  office: '',
  reg_date: '',
  description: '',
  errors: {},
}

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setValue(state, action) {
      Object.keys(action.payload).forEach((key) => {
        if (key in state.errors) {
          delete state.errors[key as keyof typeof state.errors]
        }
      })
      Object.assign(state, action.payload)
    },
    reset(state, action) {
      return { ...initialState }
    },
  },
})

export const { setValue, reset } = organizationSlice.actions
export default organizationSlice.reducer
