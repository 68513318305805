import { createSlice } from '@reduxjs/toolkit'
import { message } from 'antd'
import { patch } from 'src/utils/requests'
import { API_PROFILE } from 'src/api'

const initialState = {
  fetchData: true,
  image: null,
  imageSource: null,
  first_name: null,
  last_name: null,
  gender: null,
  language: null,
  language_label: null,
  email: null,
  country: null,
  country_label: null,
  own_phone: null,
  phone_code: null,
  date_of_birth: null,
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setValue(state, action) {
      let newForm = Object()
      Object.assign(newForm, state, action.payload)
      if (typeof action.payload.image === 'string') {
        delete newForm['image']
        newForm.imageSource = action.payload.image
      }
      return newForm
    },
    setImage(state, action) {
      let newForm = Object()
      Object.assign(newForm, state, action.payload)
      if (action.payload.image !== null) {
        newForm.imageSource = URL.createObjectURL(action.payload.image)
      } else {
        newForm.imageSource = null
      }
      return newForm
    },
    updateItem(state, action) {
      patch(`${API_PROFILE}${action.payload}/`, state, true)
        .then(() => {
          message.success('Profile updated successfully')
          state.fetchData = true
          // URL.revokeObjectURL(state.form.imageObject)
        })
        .catch(() => {})
    },
    changeActiveOrganization(_state, action) {
      patch(`${API_PROFILE}${action.payload.person}/`, action.payload)
        .then(() => {
          window.location.href = '/dashboard'
          // window.location.reload()
        })
        .catch(() => {})
    },
    changeLanguage(_state, action) {
      patch(`${API_PROFILE}${action.payload.person}/`, action.payload)
        .then(() => {
          window.location.reload()
        })
        .catch(() => {})
    },
  },
})

export const { setValue, setImage, updateItem, changeActiveOrganization, changeLanguage } =
  profileSlice.actions
export default profileSlice.reducer
