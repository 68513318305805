import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  openDrawler: false,
  activeID: null,
}

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setValue(state, action) {
      return Object.assign({}, state, action.payload)
    },
  },
})

export const { setValue } = companySlice.actions
export default companySlice.reducer
