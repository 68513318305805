import { createSlice } from '@reduxjs/toolkit'
import { loginRequest } from 'src/api/auth'

const initialState = {
  auth: {
    email: '',
    password: '',
  },
  value: 0,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state) => {
      loginRequest(state.auth)
        .then((res) => {
          if (res.status === 200) {
            document.cookie = `Authorization: Token ${res.data.key}`
            // TODO -> Redirect to history path
            window.location.href = '/profile'
          }
          if (res.status === 400) {
            // TODO -> show the alert
            // alert("Bad request");
          }
        })
        .catch(() => {})
    },
    setEmail: (state, action) => {
      state.auth.email = action.payload
    },
    setPassword: (state, action) => {
      state.auth.password = action.payload
    },
  },
})

export const { login, setEmail, setPassword } = userSlice.actions
export default userSlice.reducer
