import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  theme: 'default',
}

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setThemeDefault: (state, action) => {
      state.theme = action.payload
    },
    setThemeSomething: (state, action) => {
      state.theme = action.payload
    },
  },
})

export const { setThemeDefault, setThemeSomething } = themeSlice.actions
export default themeSlice.reducer
