import { API } from '../consts'

const HEADERS = {
  'Content-Type': 'application/json',
}

const sessionRequest = async ({ sessionid }) => {
  const response = await fetch(`${API.root}api/session/`, {
    method: 'post',
    headers: HEADERS,
    body: JSON.stringify({
      sessionid: sessionid,
    }),
  })

  const data = await response.json()
  return {
    data,
    status: response.status,
  }
}

const loginRequest = async ({ email, password }) => {
  let copiedHeaders = Object.assign({}, HEADERS)
  copiedHeaders['X-CSRFTOKEN'] = localStorage.getItem('sessionid')
  const response = await fetch(`${API.root}rest-auth/login/`, {
    method: 'post',
    headers: copiedHeaders,
    body: JSON.stringify({
      username: email,
      email,
      password,
    }),
  })

  const data = await response.json()
  return {
    data,
    status: response.status,
    token: response.headers.get('Authorization'),
  }
}

const signupRequest = async ({ email, password, password2, language }) => {
  const url = `${API.root}register/`
  // const url = `${API.root}rest-auth/registration/`
  const response = await fetch(url, {
    method: 'post',
    headers: HEADERS,
    body: JSON.stringify({
      username: email,
      email,
      password,
      password2,
      language,
    }),
  })

  const data = await response.json()
  return {
    data,
    status: response.status,
  }
}

const confirmEmailRequest = async ({ token }) => {
  const url = `${API.root}confirm-email/`
  const response = await fetch(url, {
    method: 'post',
    headers: HEADERS,
    body: JSON.stringify({
      token: token,
    }),
  })

  const data = await response.json()
  return {
    data,
    status: response.status,
  }
}

const sendPasswordResetLinkRequest = async ({ email }) => {
  const response = await fetch(`${API.root}rest-auth/password/reset/`, {
    method: 'post',
    headers: HEADERS,
    body: JSON.stringify({
      email,
    }),
  })

  const data = await response.json()
  return {
    data,
    status: response.status,
  }
}

const sendPasswordResetConfirmRequest = async ({ token, password, password2 }) => {
  const response = await fetch(`${API.root}rest-auth/password/reset/confirm/`, {
    method: 'post',
    headers: HEADERS,
    body: JSON.stringify({
      token,
      password,
      password2,
    }),
  })

  const data = await response.json()
  return {
    data,
    status: response.status,
  }
}

const setPasswordRequest = async ({ token, password1, password2 }) => {
  const response = await fetch(`${API.root}newpassword/`, {
    method: 'post',
    headers: HEADERS,
    body: JSON.stringify({
      token,
      password1,
      password2,
    }),
  })

  const data = await response.json()
  return {
    data,
    status: response.status,
  }
}

export {
  sessionRequest,
  loginRequest,
  signupRequest,
  sendPasswordResetLinkRequest,
  setPasswordRequest,
  confirmEmailRequest,
  sendPasswordResetConfirmRequest,
}
