import { configureStore } from '@reduxjs/toolkit'

import globalReducer from './slices/globalSlice'
import asideReducer from './slices/asideSlice'
import sidebarReducer from './slices/sidebarSlice'
import themeReducer from './slices/themeSlice'
import userReducer from './slices/userSlice'
import profileReducer from './slices/profileSlice'
import productReducer from './slices/productSlice'
import inquireReducer from './slices/inquireSlice'
import quotationReducer from './slices/quotationSlice'
import documentReducer from './slices/documentSlice'
import companyReducer from './slices/companySlice'
import organizationReducer from './slices/organizationSlice'
import paginationReducer from './slices/paginationSlice'
import landingReducer from './slices/landingSlice'
import cartReducer from './slices/cartSlice'

export const store = configureStore({
  reducer: {
    global: globalReducer,
    user: userReducer,
    profile: profileReducer,
    sidebar: sidebarReducer,
    aside: asideReducer,
    theme: themeReducer,
    product: productReducer,
    inquire: inquireReducer,
    quotation: quotationReducer,
    document: documentReducer,
    company: companyReducer,
    organization: organizationReducer,
    pagination: paginationReducer,
    landing: landingReducer,
    cart: cartReducer,
  },
})

export default store
