import axios from 'axios'

const HEADERS = { 'Content-Type': 'multipart/form-data' }

function getFormData(payload) {
  let formData = new FormData()
  for (const [key, value] of Object.entries(payload)) {
    formData.append(key, value === null ? '' : value)
  }
  return formData
}

export function get(url, params = {}) {
  return axios.get(url, { params: params })
}

export function post(url, payload, useFormData) {
  return call(axios.post, url, payload, useFormData)
}

export function patch(url, payload, useFormData) {
  return call(axios.patch, url, payload, useFormData)
}

export function put(url, payload, useFormData) {
  return call(axios.put, url, payload, useFormData)
}

export function del(url, payload, useFormData) {
  return call(axios.delete, url, payload, useFormData)
}

export function call(callback, url, payload, useFormData = false) {
  const data = useFormData ? getFormData(payload) : payload
  const config = !useFormData ? {} : { headers: HEADERS }
  return callback(url, data, config)
}
