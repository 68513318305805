import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: null,
  inquire: null,
  name: '',
  description: '',
  created_at: '',
  expire_at: '',
  currency: null,
  currency_requested: null,
  currency_offered: null,
  show_minimum_price: false,
  is_active: false,
  open: false,
  finished: false,
  local: false,
  delivery_time: null,
  delivery_term: '',
  payment_condition: '',
  participants: [],
  inquireitem_set: [],
  offeritem_set: [],
  documents: [],
  errors: {},
  // Offer
  offerDeliveryTerm: '',
  offerDeliveryTime: '',
  offerPaymentCondition: '',
  offerCurrency: '',
}

const quotationSlice = createSlice({
  name: 'quotation',
  initialState,
  reducers: {
    setValue(state, action) {
      Object.keys(action.payload).forEach((key) => {
        if (key in state.errors) {
          delete state.errors[key as keyof typeof state.errors]
        }
      })
      Object.assign(state, action.payload)
    },
  },
})

export const { setValue } = quotationSlice.actions
export default quotationSlice.reducer
